import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseIcon',
  props: {
  name: {
    type: String,
    required: true
  },
  position: {
    type: String,
    default: '',
    validator: (value: string) => ['', 'right', 'left', 'top', 'bottom'].includes(value)
  },
  height: {
    type: String,
    default: '1em',
    validator: (value: string) =>
      ['px', 'pt', 'cm', 'mm', 'em', 'rem', '%', 'ch', 'vh', 'lh'].some((unit) => {
        const split = value.trim().split(unit);
        return split.length && !isNaN(+split[0].trim());
      })
  },
  stroke: {
    type: Boolean,
    default: true
  }
},
  setup(__props) {





return (_ctx: any,_cache: any) => {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createBlock(_component_InlineSvg, {
    src: require(`../../../assets/icons/${__props.name}.svg`),
    style: _normalizeStyle([{"vertical-align":"-0.125em"}, { height: __props.height }]),
    class: _normalizeClass(["inline-block w-auto", [
      __props.position === 'right'
        ? 'ml-3'
        : __props.position === 'left'
          ? 'mr-3'
          : __props.position === 'top'
            ? 'mb-3'
            : __props.position === 'bottom'
              ? 'mt-3'
              : '',
      __props.stroke ? 'stroke-current' : ''
    ]])
  }, null, 8, ["src", "style", "class"]))
}
}

})