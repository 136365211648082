import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-between items-center font-medium text-base text-gray-900 px-5 py-2 bg-white border border-gray-300 shadow rounded" }
const _hoisted_2 = { class: "md:text-18" }
const _hoisted_3 = { class: "select-none text-gray-500 flex flex-row items-center" }

import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { getPages } from '@/lib/helpers/pagination.helper';
import { BaseIcon } from '@/lib/components/Icon';
import PaginationButton from './PaginationButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BasePagination',
  props: {
  modelValue: {
    type: Number,
    default: 1
  },
  pageSize: {
    type: Number,
    default: 10
  },
  length: {
    type: Number,
    default: 0
  },
  mdPagesLimit: {
    type: Number,
    default: 8
  },
  smPagesLimit: {
    type: Number,
    default: 7
  },
  itemsName: {
    type: String,
    default: 'items'
  }
},
  emits: ["update:modelValue", "change"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const pagesLimit = ref(0);

const pagesList = computed(() => {
  // FIXME: it is not updated when length and pageSize are updated
  // return [...Array(Math.ceil(props.length / props.pageSize)).keys()].map((i: number) => i + 1);
  const list: number[] = [];
  for (let i = 1; i <= Math.ceil(props.length / props.pageSize); i++) {
    list.push(i);
  }
  return list;
});
const displayedList = computed(() => getPages(pagesList.value, pagesLimit.value, props.modelValue));

watch([() => props.mdPagesLimit, () => props.smPagesLimit], () => {
  exposed.setPageLimit();
});

onBeforeMount(() => {
  exposed.setPageLimit();
  // On window resize, update pages limit
  window.addEventListener('resize', exposed.setPageLimit);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', exposed.setPageLimit);
});
const setPageLimit = () => {
  const mdScreen = 768;
  const limit = window.innerWidth >= mdScreen ? props.mdPagesLimit : props.smPagesLimit;
  if (limit !== pagesLimit.value) {
    pagesLimit.value = limit;
  }
};
const updatePage = (page: number) => {
  if (props.modelValue !== page && page) {
    emit('update:modelValue', page);
    emit('change', page);
  }
};
const previousPage = () => {
  if (props.modelValue > 1) {
    exposed.updatePage(props.modelValue - 1);
  }
};
const nextPage = () => {
  if (props.modelValue < pagesList.value.length) {
    exposed.updatePage(props.modelValue + 1);
  }
};
const exposed = {
  setPageLimit,
  updatePage
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('platform.common.pagination', [
          (__props.modelValue - 1) * __props.pageSize + 1,
          pagesList.value.length === __props.modelValue ? __props.length : __props.modelValue * __props.pageSize,
          __props.length,
          __props.itemsName
        ])), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(displayedList.value, (page) => {
        return (_openBlock(), _createBlock(PaginationButton, {
          key: page,
          active: __props.modelValue === page,
          disabled: !page,
          "data-dusk": `pagination-button-${page}`,
          onClick: ($event: any) => (updatePage(page))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(page ? page : '...'), 1)
          ]),
          _: 2
        }, 1032, ["active", "disabled", "data-dusk", "onClick"]))
      }), 128)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "border-r border-gray-300 h-10 mx-2" }, null, -1)),
      _createVNode(PaginationButton, {
        disabled: __props.modelValue === 1,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (previousPage()))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseIcon), { name: "arrow-left" })
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(PaginationButton, {
        disabled: __props.modelValue === pagesList.value.length,
        "data-dusk": "pagination-button-next",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (nextPage()))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseIcon), { name: "arrow-right" })
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}
}

})