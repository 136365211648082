import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "pb-3"
}
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = ["for"]
const _hoisted_5 = {
  key: 0,
  class: "text-gray-500 font-medium text-xs"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["id", "disabled", "value", "readonly", "placeholder", "aria-placeholder"]
const _hoisted_8 = ["disabled"]

import { ref, useId } from 'vue';
import { BaseIcon } from '@/lib/components/Icon';
import { InputTextHelper } from '@/lib/components/Helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseNumberInput',
  props: {
  label: {
    type: String,
    default: ''
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  showButton: {
    type: Boolean,
    default: true
  },
  error: {
    type: String,
    default: null
  }
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {


const emit = __emit;

const id = useId();
const input = ref(null);

const stepUp = () => {
  input.value.stepUp();

  emit('update:modelValue', input.value.value);
};
const stepDown = () => {
  input.value.stepDown();

  emit('update:modelValue', input.value.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", {
              for: _unref(id),
              class: "text-base font-medium leading-tight"
            }, _toDisplayString(__props.label), 9, _hoisted_4),
            (__props.isOptional)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["inline-flex text-base h-12 w-full font-medium items-center bg-white border text-gray-900 shadow rounded", {
        'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
          __props.error
      }])
    }, [
      (__props.showButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            disabled: __props.disabled || __props.readonly,
            class: _normalizeClass([{ 'cursor-default bg-gray-100 text-gray-400': __props.disabled }, "w-1/4 border-r border-gray-300 focus:outline-none"]),
            onClick: stepDown
          }, [
            _createVNode(_unref(BaseIcon), { name: "minus" })
          ], 10, _hoisted_6))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "left"),
      _createElementVNode("input", _mergeProps(_ctx.$attrs, {
        id: _unref(id),
        ref_key: "input",
        ref: input,
        class: ["ml-4 w-full h-full focus:outline-none flex-grow spin-appearance-none", [{ 'bg-gray-100 text-gray-400': __props.disabled }]],
        type: "number",
        disabled: __props.disabled,
        value: __props.modelValue,
        readonly: __props.readonly,
        placeholder: __props.placeholder,
        "aria-placeholder": __props.placeholder,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value)))
      }), null, 16, _hoisted_7),
      (__props.showButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: _normalizeClass(["w-1/4 border-l border-gray-300 focus:outline-none", { 'cursor-default bg-gray-100 text-gray-400': __props.disabled }]),
            disabled: __props.disabled || __props.readonly,
            onClick: stepUp
          }, [
            _createVNode(_unref(BaseIcon), { name: "add" })
          ], 10, _hoisted_8))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "use-default")
    ], 2),
    _createVNode(_unref(InputTextHelper), {
      error: __props.error,
      description: __props.description
    }, null, 8, ["error", "description"])
  ]))
}
}

})